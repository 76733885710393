import Link from "next/link";
import { useTranslations } from "use-intl";
import { getStaticPropsForMessages } from "../src/util/utilServer";

function ErrorPage() {
  const t = useTranslations("ErrorPage");
  return (
    <>
      <h2>{t("not_found")}</h2>
      <Link href={"/"}>
        <span style={{ textDecoration: "underline", fontSize: "1.2rem" }}>
          {t("root")}
        </span>
      </Link>
    </>
  );
}

ErrorPage.Messages = ["ErrorPage"];

export default ErrorPage;

export const getStaticProps = getStaticPropsForMessages(ErrorPage.Messages);
